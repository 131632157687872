import { tableDate } from 'helpers/date';
import { getInitials } from 'helpers/utils';
import { Row } from 'reactstrap';

const RecentActivity = ({ isMini, donors }) => {
  return (
    <div className={`recent-activity-sidebar ${isMini ? 'recent-activity-hidden' : ''}`}>
      {donors?.length ? (
        donors.slice(0, 10).map((donor, index) => (
          <div className="recent-activity-item" key={index}>
            <div className="recent-activity-initials">
              <span className="oath-body">{getInitials(donor.name)}</span>
            </div>
            <div style={{ width: 'calc(100% - 40px)' }}>
              <Row className="space-between">
                <span className="oath-bodysmall inkBlue">{donor.name}</span>
                <span className="oath-bodysmall inkBlue">${donor.donation_amount}</span>
              </Row>
              <span className="oath-utility gray" style={{ textDecoration: 'underline' }}>
                {donor.page}
              </span>
              <br />
              <span className="oath-utility gray">{tableDate(donor.time)}</span>
            </div>
          </div>
        ))
      ) : (
        <div className="recent-activity-item">
          <span className="oath-bodysmall inkBlue">No recent activity on this period.</span>
        </div>
      )}
    </div>
  );
};

export default RecentActivity;
