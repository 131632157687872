import colors from './colors';

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  },
  scales: {
    y: {
      ticks: {
        color: colors.gray,
        beginAtZero: false,
        maxTicksLimit: 5,
      },
      grid: {
        drawBorder: false,
        display: true,
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        padding: 20,
        color: colors.gray,
      },
    },
  },
};

export const chartInitialValues = {
  data: {
    labels: <string[]>[],
    datasets: [
      {
        label: ' [$] Total',
        borderColor: colors.oathBlue,
        pointRadius: 2,
        pointHoverRadius: 10,
        fill: false,
        borderWidth: 3,
        barPercentage: 1.6,
        tension: 0.4,
        data: <number[]>[],
      },
    ],
  },
  options: chartOptions,
};
