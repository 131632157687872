import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { batch } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import { has_permission } from 'config/permissions';
import { chartInitialValues } from 'constants/chart';
import Loader from 'components/Loader';
import Icon, { IconNames } from 'components/Icon';
import Sidebar from 'components/Sidebar';
import { SmallCard } from 'components/SmallCard';
import RegularTable from 'components/RegularTable';
import { CustomHeader } from 'components/CustomHeader';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { urlDate, chartDate } from 'helpers/date';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { numberWithDot, percentDiff, sumAmounts } from 'helpers/utils';
import { getUserState } from 'selectors/user';
import { getDonorData } from 'selectors/donors';
import { getSummaryData } from 'selectors/summary';
import { getTotalTransactionData } from 'selectors/transactions';
import { getTotalDonationData, getTrendDonationData } from 'selectors/donations';
import { getAccessToken, getSessionEndDate, getSessionStartDate } from 'services/storage';
import { getTableDonors } from 'store/donor/actions';
import { getTableSummary } from 'store/summary/actions';
import { getTransactionTotal } from 'store/transaction/actions';
import { getDonationTotal, getDonationTrend } from 'store/donation/actions';
import { ISummaryTotals } from 'store/summary/types';
import RecentActivity from 'components/RecentActivity';
import colors from 'constants/colors';
import { dimensions } from 'constants/dimensions';
import { DateFilters } from 'components/Filters/DateFilters';
import { summaryPageColumns, summaryRecipientColumns } from 'constants/tables';
import { setUserDates } from 'store/user/actions';

const Dashboard = () => {
  const mainPanel = useRef<any>();
  const dispatch = useAppDispatch();
  const [donationDiff, setDonationDiff] = useState('-');
  const [transactionDiff, setTransactionDiff] = useState('-');
  const [chartData, setChartData] = useState(chartInitialValues);
  const [recentActivityMini, setRecentActivityMini] = useState(false);
  const [dimension, setDimension] = useState('recipient');
  const [showCustomHeader, setShowCustomHeader] = useState(false);

  const Today = new Date();
  const Yesterday = new Date().setDate(new Date().getDate() - 1);

  const {
    userState,
    totalDonation,
    totalTransaction,
    donationTrend,
    summary,
    donors,
    pageLoaderLoading,
  } = useAppSelector(state => ({
    userState: getUserState(state),
    totalDonation: getTotalDonationData(state),
    totalTransaction: getTotalTransactionData(state),
    donationTrend: getTrendDonationData(state),
    summary: getSummaryData(state),
    donors: getDonorData(state),
    pageLoaderLoading:
      state.donation.isLoading ||
      state.transaction.isLoading ||
      state.summary.isLoading ||
      state.donor.isLoading,
  }));

  useEffect(() => {
    if (
      userState?.clientID &&
      userState?.dates?.start &&
      !userState?.datesUpdated &&
      getAccessToken()
    ) {
      batch(() => {
        dispatch(
          getDonationTotal(
            'today',
            urlDate(Today, 'start'),
            urlDate(Today, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getDonationTotal(
            'yesterday',
            urlDate(Yesterday, 'start'),
            urlDate(Yesterday, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTransactionTotal(
            'today',
            urlDate(Today, 'start'),
            urlDate(Today, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTransactionTotal(
            'yesterday',
            urlDate(Yesterday, 'start'),
            urlDate(Yesterday, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getDonationTrend(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTableSummary(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            dimension,
            userState.clientID
          )
        );
        dispatch(
          getTableDonors(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
      });
    }
  }, [userState]);

  useEffect(() => {
    if (userState?.clientID && userState?.dates?.start) {
      dispatch(
        getTableSummary(
          urlDate(userState.dates.start, 'start'),
          urlDate(userState.dates.end, 'end'),
          dimension,
          userState.clientID
        )
      );
    }
  }, [dimension]);

  useEffect(() => {
    if (userState?.clientID && userState?.datesUpdated && userState?.dates?.start) {
      batch(() => {
        dispatch(
          getDonationTrend(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
        dispatch(
          getTableSummary(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            dimension,
            userState.clientID
          )
        );
        dispatch(
          getTableDonors(
            urlDate(userState.dates.start, 'start'),
            urlDate(userState.dates.end, 'end'),
            userState.clientID
          )
        );
      });
    }
  }, [userState?.dates]);

  useEffect(() => {
    if (!userState.dates.start) {
      dispatch(
        setUserDates(
          parseInt(getSessionStartDate() || ''),
          parseInt(getSessionEndDate() || ''),
          true
        )
      );
    }
  }, []);

  useEffect(() => {
    if (totalDonation?.today !== undefined && totalDonation?.yesterday !== undefined) {
      setDonationDiff(percentDiff(totalDonation.today, totalDonation.yesterday));
    }
  }, [totalDonation]);

  useEffect(() => {
    if (totalTransaction?.today !== undefined && totalTransaction?.yesterday !== undefined) {
      setTransactionDiff(percentDiff(totalTransaction.today, totalTransaction.yesterday));
    }
  }, [totalTransaction]);

  useEffect(() => {
    if (donationTrend?.length > 0) {
      const newData = chartData;
      newData.data.labels = donationTrend.map(item => chartDate(item.date));
      newData.data.datasets[0].data = donationTrend.map(item => parseInt(item.amount));
      setChartData(newData);
    }
  }, [donationTrend]);

  return (
    <>
      {pageLoaderLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <Row className="filters-row">
            <DateFilters setIsCustom={setShowCustomHeader} />
            <div
              className={`recent-activity-clickable ${recentActivityMini ? 'recent-activity-mini' : ''}`}
              onClick={() => setRecentActivityMini(prev => !prev)}
            >
              <Icon
                name={IconNames.ChevronLeft}
                className="chevron-left"
                color="#04053A"
                style={{ display: 'none' }}
              />
              <span className="oath-h4 inkBlue">Recent Activity</span>
              <Icon name={IconNames.ChevronRight} className="chevron-right" color="#04053A" />
            </div>
          </Row>
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className={`content ${recentActivityMini ? 'content-mini' : ''}`}>
              <RecentActivity isMini={recentActivityMini} donors={donors} />
              <div className="custom-header-container">
                {showCustomHeader && <CustomHeader align="center" />}
              </div>
              <Row>
                <Col lg="4" md="4" sm="12">
                  <SmallCard
                    title="Raised Today"
                    subtitle="Yesterday"
                    titleValue={`$${numberWithDot(totalDonation?.today)}`}
                    subtitleValue={`$${numberWithDot(totalDonation?.yesterday)}`}
                    badgeStyle={`${parseInt(donationDiff) > 0 ? 'blue' : 'orange'}-badge`}
                    badgeValue={`${donationDiff} %`}
                    icon={IconNames.MoneyBill}
                  />
                  <SmallCard
                    title="Transactions Today"
                    subtitle="Yesterday"
                    titleValue={totalTransaction?.today?.toString() || '...'}
                    subtitleValue={totalTransaction?.yesterday?.toString() || '...'}
                    badgeStyle={`${parseInt(transactionDiff) > 0 ? 'blue' : 'orange'}-badge`}
                    badgeValue={`${transactionDiff} %`}
                    icon={IconNames.Arrows}
                  />
                </Col>
                <Col lg="8" md="8" sm="12">
                  <Card>
                    <CardHeader>
                      <div className="card-row">
                        <Icon name={IconNames.MoneyBill} color={colors.inkBlue} size={20} />
                        <span className="oath-body inkBlue">Recent Giving Activity</span>
                      </div>
                      <span className="oath-h3 inkBlue">
                        ${' '}
                        {numberWithDot(
                          sumAmounts(donationTrend?.map(item => parseFloat(item.amount)))?.toFixed(
                            2
                          )
                        )}
                      </span>
                    </CardHeader>
                    <CardBody>
                      {chartData.data.datasets[0].data.length > 0 && (
                        <Line
                          data={chartData.data}
                          options={chartData.options}
                          height={380}
                          width={826}
                          redraw={true}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <RegularTable<ISummaryTotals>
                    title={`Summary (By ${dimension})`}
                    subtitle="Listed in order of donation date"
                    columns={dimension === 'page' ? summaryPageColumns : summaryRecipientColumns}
                    data={summary?.filter(
                      item =>
                        !item?.recipient?.includes('Oath') &&
                        !item?.recipient?.includes('51C Collaborative')
                    )}
                    dimensionOnChange={setDimension}
                    dimensionOptions={dimensions}
                    withPagination
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
