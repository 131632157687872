import { useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { Row, Col } from 'reactstrap';
import { has_permission } from 'config/permissions';
import { donorColumns, ITableColumn } from 'constants/tables';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import Icon, { IconNames } from 'components/Icon';
import { CustomTable } from 'components/CustomTable';
import { CustomHeader } from 'components/CustomHeader';
import RecentActivity from 'components/RecentActivity';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { numberWithDot } from 'helpers/utils';
import { urlDate, tableDate } from 'helpers/date';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { getDonorData, getDonorState } from 'selectors/donors';
import { getClientID, getAccessToken } from 'services/storage';
import { getTableDonors } from 'store/donor/actions';
import { DonationFilters } from 'components/Filters/DonationFilters';

interface IData {
  id: number;
  date: string;
  name: string;
  amount: string;
  page: string;
  ref: string;
  email: string;
  city: string;
  state: string;
  transaction_id: string;
}
const DonorList = () => {
  const mainPanel = useRef<any>();
  const dispatch = useAppDispatch();
  const [dataState, setDataState] = useState(Array<IData>());
  const [recentActivityMini, setRecentActivityMini] = useState(false);
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>(donorColumns);

  const { donors, donorsState, userState } = useAppSelector(state => ({
    donors: getDonorData(state),
    donorsState: getDonorState(state),
    userState: getUserState(state),
  }));

  useEffect(() => {
    if (getClientID() && userState.dates?.start && userState.dates?.end && getAccessToken()) {
      dispatch(
        getTableDonors(
          urlDate(userState.dates.start, 'start'),
          urlDate(userState.dates.end, 'end'),
          getClientID() || ''
        )
      );
    }
  }, [userState]);

  useEffect(() => {
    if (donors) {
      setDataState(
        donors?.map((prop, key) => {
          return {
            id: key,
            date: tableDate(prop.time),
            name: prop.name,
            amount: `$${prop.donation_amount}`,
            page: prop.page,
            ref: prop.ref_code,
            city: prop.user_city,
            email: prop.user_email,
            state: prop.user_state,
            transaction_id: prop.transaction_id,
          };
        })
      );
    }
  }, [donors]);

  return (
    <>
      {donorsState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <Row className="filters-row">
            <DonationFilters />
            <div
              className={`recent-activity-clickable ${recentActivityMini ? 'recent-activity-mini' : ''}`}
              onClick={() => setRecentActivityMini(prev => !prev)}
            >
              <Icon
                name={IconNames.ChevronLeft}
                className="chevron-left"
                color="#04053A"
                style={{ display: 'none' }}
              />
              <span className="oath-h4 inkBlue">Recent Activity</span>
              <Icon name={IconNames.ChevronRight} className="chevron-right" color="#04053A" />
            </div>
          </Row>
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className={`content ${recentActivityMini ? 'content-mini' : ''}`}>
              <RecentActivity isMini={recentActivityMini} donors={donors} />
              <Row className="custom-header-container">
                <Col lg="4" md="4" sm="12" className="grid-column">
                  <span className="oath-h3 inkBlue">
                    $
                    {numberWithDot(
                      donors?.reduce((acum, item) => acum + item.donation_amount, 0)?.toFixed(2)
                    )}{' '}
                    Raised
                  </span>
                  <span className="oath-body inkBlue">{donors?.length} Donations</span>
                </Col>
                <Col lg="8" md="8" sm="12" className="grid-column">
                  <CustomHeader />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <CustomTable
                    title="Most Recent Donations"
                    subtitle="Listed in order of donation date"
                    data={dataState}
                    columns={tableColumns}
                    withPagination
                    withDownloadCSV
                    withColumnFilter
                    updateColumns={setTableColumns}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DonorList;
