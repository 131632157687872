import { useEffect, useState } from 'react';
import ReactDatetime from 'react-datetime';
import { CardTitle, Col, FormGroup, Row } from 'reactstrap';
import { inputDate } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import {
  getSessionStartDate,
  getSessionEndDate,
  setSessionStartDate,
  setSessionEndDate,
} from 'services/storage';
import { setUserDates } from 'store/user/actions';

export const CustomHeader = ({ align = 'right' }) => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();

  useEffect(() => {
    if (getSessionStartDate() && getSessionEndDate()) {
      setStartDate(parseInt(getSessionStartDate() || ''));
      setEndDate(parseInt(getSessionEndDate() || ''));
    }
  }, [getSessionStartDate()]);

  const handleChangeDates = (type: string, item) => {
    if (type === 'start') {
      setStartDate(new Date(item).getTime());
      dispatch(setUserDates(new Date(item).getTime(), new Date(endDate || '').getTime(), true));
      setSessionStartDate(new Date(item).getTime().toString());
    } else {
      setEndDate(new Date(item).getTime());
      dispatch(setUserDates(new Date(startDate || '').getTime(), new Date(item).getTime(), true));
      setSessionEndDate(new Date(item).getTime().toString());
    }
  };

  return (
    <>
      {startDate && endDate && (
        <Row style={{ justifyContent: align }}>
          <Col md="3" sm="6" xs="6">
            <CardTitle>Start Date</CardTitle>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: 'form-control',
                  placeholder: 'Date Picker Here',
                }}
                onChange={e => handleChangeDates('start', e)}
                value={inputDate(startDate)}
                closeOnSelect
                timeFormat={false}
              />
            </FormGroup>
          </Col>
          <Col md="3" sm="6" xs="6">
            <CardTitle>End Date</CardTitle>
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: 'form-control',
                  placeholder: 'Date Picker Here',
                }}
                onChange={e => handleChangeDates('end', e)}
                value={inputDate(endDate)}
                closeOnSelect
                timeFormat={false}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
};
