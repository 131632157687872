import React, { FC, useState } from 'react';
import classnames from 'classnames';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { CSVLink } from 'react-csv';
import { matchSorter } from 'match-sorter';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import { Col, FormGroup, Input, CardBody, Card } from 'reactstrap';
import { inputDate } from 'helpers/date';
import { buttonTypes, ITableColumn } from 'constants/tables';
import { ColumnsToggle } from './ColumnsToggle';
import Icon, { IconNames } from 'components/Icon';
import colors from 'constants/colors';

export type onChangeType = React.ChangeEvent<HTMLInputElement>;

interface Props {
  columns: ITableColumn[];
  data: any;
  title?: string;
  subtitle: string;
  pageSize?: number;
  withPagination?: boolean;
  withDownloadCSV?: boolean;
  withColumnFilter?: boolean;
  updateColumns?: (columns: ITableColumn[]) => void;
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e: onChangeType) => {
          setFilter(e.target.value || undefined);
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val;

export const CustomTable: FC<Props> = ({
  columns,
  data,
  title,
  subtitle,
  pageSize,
  withPagination,
  withDownloadCSV,
  withColumnFilter,
  updateColumns,
}) => {
  const [alert, setAlert] = useState<any>(null);
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setAlert(
      <ReactBSAlert
        style={{ width: 240 }}
        title={
          <div className="text-container">
            <Icon name={IconNames.Copy} color={colors.oathBlue} />
            <span className="alert-link">Link copied!</span>
          </div>
        }
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        showConfirm={false}
      />
    );
    setTimeout(() => setAlert(null), 2000);
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: pageSize || 10, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const csvData = [
    [' ', ...columns.filter(column => column.visible).map(col => col.Header)],
    ...data.map((rowData, i) => [
      i,
      ...columns
        .filter(column => column.visible)
        .map(col => col.accessor)
        .map(columnName => rowData[columnName]),
    ]),
  ];

  return (
    <Card>
      <div className="CustomTableHeader">
        <div className="grid-column">
          <span className="oath-h4 inkBlue capitalize">{title}</span>
          <span className="oath-bodysmall gray">{subtitle}</span>
        </div>
        <div className="FilterOptions">
          {withDownloadCSV && (
            <CSVLink
              className="csv-link"
              filename={`Oath_Donations_${inputDate(new Date().getTime())}.csv`}
              data={csvData}
            >
              Export to CSV
            </CSVLink>
          )}
          {withColumnFilter && <ColumnsToggle onSave={updateColumns} columns={columns} />}
        </div>
      </div>
      <CardBody>
        <div className="ReactTable -striped -highlight oathBlue-pagination">
          <table {...getTableProps()} className="table-scroll">
            <thead className="rt-thead -header">
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr" key={i}>
                  {headerGroup.headers
                    .filter(column => column.visible)
                    .map((column, key) => (
                      <th
                        key={key}
                        className={classnames('rt-th rt-resizable-header', {
                          '-cursor-pointer': headerGroup.headers[key].Header !== 'Actions',
                          '-sort-asc': column.isSorted && !column.isSortedDesc,
                          '-sort-desc': column.isSorted && column.isSortedDesc,
                        })}
                      >
                        <div
                          className="oath-eyebrow inkBlue"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          style={{
                            textAlign: 'left',
                            paddingBottom: 8,
                          }}
                        >
                          {column.render('Header')}
                        </div>
                        <div>
                          {headerGroup.headers[key].Header === 'Actions'
                            ? null
                            : column.canFilter
                              ? column.render('Filter')
                              : null}
                        </div>
                      </th>
                    ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
              {alert}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={classnames(
                      'rt-tr',
                      { ' -odd': i % 2 === 0 },
                      { ' -even': i % 2 === 1 }
                    )}
                  >
                    {row.cells
                      .filter(cell => cell.column.visible)
                      .map((cell, key) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="rt-td oath-bodysmall inkBlue"
                            key={key}
                          >
                            <span
                              className={cell.column.Header === 'Website URL' ? 'link-text' : ''}
                              onClick={() =>
                                cell.column.Header === 'Website URL' && handleCopy(cell.value)
                              }
                            >
                              {cell.render('Cell')}
                            </span>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          {withPagination && (
            <div className="pagination-bottom">
              <div className="-pagination">
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-previous">
                    <button
                      type="button"
                      onClick={previousPage}
                      disabled={!canPreviousPage}
                      className="-btn"
                    >
                      Previous
                    </button>
                  </div>
                </Col>
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-next">
                    <button
                      type="button"
                      onClick={nextPage}
                      disabled={!canNextPage}
                      className="-btn"
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

export const tableButton = (handlePress, type, id) => {
  return (
    <Col md="6" style={{ padding: 0 }}>
      <div className="table-action-link inkBlue" onClick={() => handlePress(type, id)}>
        <Icon name={buttonTypes[type].icon} color={colors.inkBlue} />
        <span>{type}</span>
      </div>
    </Col>
  );
};
