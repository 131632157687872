import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Col,
  Dropdown,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV_CONFIG } from 'config/environment';
import { getIsSuper, getIsDev } from 'config/permissions';
import { Toast } from 'components/Toast';
import Icon, { IconNames } from 'components/Icon';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserData, getUserState } from 'selectors/user';
import {
  clearLocalStorage,
  setClientID,
  setClientName,
  getClientID,
  getClientIDs,
  getClientNames,
  getClientName,
  getUserName,
} from 'services/storage';
import { setUserClientID } from 'store/user/actions';
import colors from 'constants/colors';

interface IClientID {
  value: string;
  label: string;
}

const AdminNavbar = () => {
  const { logout } = useAuth0();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clients, setClients] = useState(Array<IClientID>());
  const [clientIDSelected, setClientIDSelected] = useState<IClientID>();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const userState = useAppSelector(getUserState);

  const userData = useAppSelector(getUserData);

  useEffect(() => {
    if (window.outerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }, [location]);

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    clearLocalStorage();
    logout({ logoutParams: { returnTo: ENV_CONFIG().SITE_URL } });
  };

  const handleChangeClient = item => {
    dispatch(setUserClientID(item.value)); //ReduxState
    setClientIDSelected(item); //UseState
    setClientID(item.value); //LocalStorage
    setClientName(item.label); //LocalStorage
  };

  useEffect(() => {
    let clientIDs = Array<string>();
    let clientNames = Array<string>();
    const arr = Array<IClientID>();
    if (userState.clients?.length) {
      clientIDs = userState.clients.map(client => client.id);
      clientNames = userState.clients.map(client => client.name);
    } else if (getClientID() && getClientIDs()) {
      clientIDs = getClientIDs()?.split(',') || [];
      clientNames = getClientNames()?.split(',') || [];
    }

    if (!clients?.length && clientIDs.length && clientNames.length) {
      for (let i = 0; i < clientIDs.length; i++) {
        arr.push({ value: clientIDs[i], label: clientNames[i] });
      }
      if (getIsSuper()) arr.unshift({ value: 'None', label: 'ALL' });
      setClients(arr);
      setClientIDSelected({
        label: getClientName() || arr[0].label,
        value: getClientID() || arr[0].value,
      });
      dispatch(setUserClientID(getClientID() || ''));
    }
  }, [userState.clients]);

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon
          name={props.selectProps.menuIsOpen ? IconNames.ChevronUp : IconNames.ChevronDown}
          color="#04053A"
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Navbar className={classnames('navbar-absolute fixed-top navbar-transparent')} expand="lg">
        <Toast />
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classnames('navbar-toggle', {
                toggled: sidebarOpen,
              })}
            >
              <button className="navbar-toggler" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#" onClick={e => e.preventDefault()}>
              <span className="d-none d-md-block">{userData?.client_name}</span>
            </NavbarBrand>
          </div>
          {(getIsSuper() || getIsDev()) && (
            <Col lg="3" md="6" sm="6" xs="6" className="navbar-clients-col">
              <Select
                className="navbar-clients"
                classNamePrefix="react-select"
                name="singleSelect"
                value={clientIDSelected}
                onChange={handleChangeClient}
                options={clients}
                placeholder="Select Client"
                components={{ DropdownIndicator }}
              />
              <span className="oath-bodysmall gray">{clients.length} Admin Profiles</span>
            </Col>
          )}
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            onClick={() => setCollapseOpen(isOpen => !isOpen)}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Col md="6" sm="6" xs="6" className="navbar-clients-col">
            <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
              <Icon
                name={IconNames.User}
                className="oath-user-svg"
                color={colors.white}
                size={20}
              />
              <div className="navbar-user">
                <span className="oath-body inkBlue">{getUserName()}</span>
                <span className="oath-utility inkBlue">{getClientName()}</span>
              </div>
              <Nav navbar>
                <Dropdown className="btn-rotate" nav isOpen={isOpen} toggle={toggle}>
                  <DropdownToggle
                    aria-haspopup={true}
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <Icon
                      name={isOpen ? IconNames.ChevronUp : IconNames.ChevronDown}
                      className="chevron-svg"
                      color={colors.inkBlue}
                    />
                    <p>
                      <span className="d-lg-none d-md-block">Settings</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                    {/* {(getIsSuper() || getIsDev()) && (
                      <>
                        <DropdownItem href="#" onClick={() => console.log('go to settings')}>
                          Settings
                        </DropdownItem>
                        <DropdownItem divider />
                      </>
                    )} */}
                    <DropdownItem href="#" onClick={handleLogout}>
                      Log Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
