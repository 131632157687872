import { useState } from 'react';

export const CustomSwitch = ({ options, onSelect }) => {
  const [activeIndex, setActiveIndex] = useState(options[0].value);
  const handleSelect = option => {
    setActiveIndex(option.value);
    onSelect(option.label);
  };

  return (
    <div className="switch-options-row">
      {options?.map(option => (
        <button
          key={option.value}
          className={`switch-option ${activeIndex === option.value ? 'active' : ''}`}
          onClick={() => handleSelect(option)}
        >
          <span className="oath-utility capitalize">{option.label}</span>
        </button>
      ))}
    </div>
  );
};
