import { useState } from 'react';
import { filterPagesOptions } from 'constants/filters';

export const PagesFilters = ({ setFilterSelected }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSelect = (state, index) => {
    setActiveIndex(index);
    setFilterSelected(state);
  };

  return (
    <div className="date-filter-container">
      {filterPagesOptions.map((option, index) => (
        <button
          key={index}
          className={`date-filter-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => handleSelect(option.value, index)}
        >
          <span className="oath-body inkBlue">{option.label}</span>
        </button>
      ))}
    </div>
  );
};
