import { getRoleType } from 'services/storage';

const PROD_CONFIG = {
  MODE: 'PRODUCTION',
  SITE_URL: 'https://admin.oath.vote',
  PARTNER_PAGE: 'https://app.oath.vote/donate?p=',
  CLOUD_STORAGE: {
    URL: 'https://storage.googleapis.com/oath_1',
    TAGS: 'tags',
  },
  AUTH: {
    DOMAIN: 'admin-oath-vote.us.auth0.com',
    CLIENT_ID: 'HKIDZVOJw3ysaUIPhp4TxYXEmDNHKBDa',
  },
  API: {
    BASE_URL: 'https://api-prod-0fto.onrender.com',
  },
};

const LOCAL_CONFIG = {
  MODE: 'LOCALHOST',
  // SITE_URL: 'https://oath.vote.local:3000',
  SITE_URL: 'https://test-admin.oath.vote',
  PARTNER_PAGE: 'https://test.oath.vote/donate?p=',
  CLOUD_STORAGE: {
    URL: 'https://storage.googleapis.com/oath_1',
    TAGS: 'test_tags',
  },
  AUTH: {
    DOMAIN: 'login-admin-oath.us.auth0.com',
    CLIENT_ID: '40uMFBgl3bRwlcAlPJFR7Ra9NtNJX17Y',
  },
  API: {
    BASE_URL: 'https://api-staging-9sw6.onrender.com',
  },
};

export const ENV_CONFIG = () => {
  let config_response = LOCAL_CONFIG;
  if (window.location.origin === 'https://admin.oath.vote') config_response = PROD_CONFIG;

  if (process.env.REACT_APP_PRODUCTION && getRoleType()?.includes(process.env.REACT_APP_PRODUCTION))
    config_response = {
      ...config_response,
      MODE: PROD_CONFIG.MODE,
      API: { BASE_URL: PROD_CONFIG.API.BASE_URL },
      AUTH: PROD_CONFIG.AUTH,
      PARTNER_PAGE: PROD_CONFIG.PARTNER_PAGE,
      CLOUD_STORAGE: PROD_CONFIG.CLOUD_STORAGE,
    };
  else if (process.env.REACT_APP_STAGING && getRoleType()?.includes(process.env.REACT_APP_STAGING))
    config_response = {
      ...config_response,
      MODE: LOCAL_CONFIG.MODE,
      API: { BASE_URL: LOCAL_CONFIG.API.BASE_URL },
      AUTH: LOCAL_CONFIG.AUTH,
      PARTNER_PAGE: LOCAL_CONFIG.PARTNER_PAGE,
      CLOUD_STORAGE: LOCAL_CONFIG.CLOUD_STORAGE,
    };
  return config_response;
};
