export const filterDonationOptions = ['By Donation'];

export const filterPagesOptions = [
  {
    label: 'All Pages',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Paused',
    value: 'PAUSED',
  },
  {
    label: 'Archive',
    value: 'ARCHIVED',
  },
];

export const filterDateOptions = [
  {
    label: 'Today',
    value: new Date(),
  },
  {
    label: 'Week',
    value: new Date().setDate(new Date().getDate() - 6),
  },
  {
    label: 'Month',
    value: new Date().setDate(new Date().getDate() - 30),
  },
  {
    label: 'Quarter',
    value: new Date().setDate(new Date().getDate() - 90),
  },
  {
    label: 'Year',
    value: new Date().setDate(new Date().getDate() - 365),
  },
  {
    label: 'Cycle',
    value: new Date().setDate(new Date().getDate() - 730),
  },
  {
    label: 'Custom',
    value: new Date(),
  },
];
