import { FC, useCallback, useState } from 'react';
import { createEditor, BaseEditor, Descendant } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { IconNames } from 'components/Icon';
import { isKeyInObject } from 'helpers/utils';
import { Toolbar } from './Toolbar';
import { deserialize, toggleMark } from './utils';
import { HOTKEYS } from './constants';
import { ElementNode, SlateNode, Props } from './types';
import { BlockButton, Leaf, MarkButton, SlateElement } from './Elements';
import colors from 'constants/colors';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: ElementNode;
    Text: SlateNode;
  }
}

export const SlateInput: FC<Props> = ({ value, onChange, id }) => {
  const renderElement = useCallback(props => <SlateElement {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const [editor] = useState(() => withHistory(withReact(createEditor())));

  let initialValue: Descendant[] = deserialize(
    new DOMParser().parseFromString(value, 'text/html').body
  );

  const defaultInitialValue: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: value }],
    },
  ];

  if (initialValue[0]?.type !== 'paragraph') initialValue = defaultInitialValue;

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
      <Toolbar>
        <MarkButton format="bold" icon={IconNames.Bold} />
        <MarkButton format="italic" icon={IconNames.Italic} />
        <MarkButton format="underline" icon={IconNames.Underline} />
        <MarkButton format="code" icon={IconNames.Embed} />
        <BlockButton format="numbered-list" icon={IconNames.ListNumbered} />
        <BlockButton format="bulleted-list" icon={IconNames.ListBulleted} />
        <BlockButton format="left" icon={IconNames.ParagraphLeft} />
        <BlockButton format="center" icon={IconNames.ParagraphCenter} />
        <BlockButton format="right" icon={IconNames.ParagraphRight} />
      </Toolbar>
      <Editable
        id={id}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        placeholder="Recommended 900 characters max"
        renderPlaceholder={({ attributes, children }) => (
          <div
            {...attributes}
            className="custom-placeholder"
            style={{
              color: colors.gray,
              fontSize: 13,
              opacity: 0.7,
              position: 'absolute',
              pointerEvents: 'none',
            }}
          >
            {children}
          </div>
        )}
        className="slate-editable"
        onKeyDown={event => {
          if (!event.ctrlKey) {
            return;
          }
          if (isKeyInObject(event.key, HOTKEYS)) {
            event.preventDefault();
            const mark = HOTKEYS[event.key];
            toggleMark(editor, mark);
          }
        }}
      />
    </Slate>
  );
};
