import { createActions } from 'reduxsauce';

export interface IRecipientState {
  data: IRecipientData;
  isLoading: boolean;
  error: string;
}
export interface IRecipientData {
  recipients: IRecipients[];
}
export interface IRecipients {
  impact_score: number;
  recipient_id: string;
  recipient_name: string;
}

export type RecipientProps = {
  search_str?: string;
  recipient_ids?: string[];
};

export interface RecipientTypes {
  RECIPIENTS: 'RECIPIENTS';
  RECIPIENTS_START: 'RECIPIENTS_START';
  RECIPIENTS_SUCCESS: 'RECIPIENTS_SUCCESS';
  RECIPIENTS_ERROR: 'RECIPIENTS_ERROR';
  RECIPIENTS_CLEAN_UP: 'RECIPIENTS_CLEAN_UP';
}

const { Types, Creators } = createActions<RecipientTypes>({
  recipients: ['data'],
  recipientsStart: null,
  recipientsSuccess: ['data'],
  recipientsError: ['error'],
  recipientsCleanUp: null,
});

export { Types };

export default Creators;
