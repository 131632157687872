const colors = {
  black: '#000000',
  darkGreen: '#093434',
  electricBlue: '#7CE3E7',
  errorRed: '#C14B0B',
  gray: '#828282',
  inkBlue: '#04053a',
  oathBlue: '#1410F9',
  softGray: '#F2F2F2',
  white: '#FFFFFF',
};

export default colors;
