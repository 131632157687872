import Icon from 'components/Icon';
import colors from 'constants/colors';
import { FC } from 'react';
import { Badge, Card, CardBody } from 'reactstrap';

interface Props {
  title: string;
  subtitle: string;
  titleValue: string;
  subtitleValue: string;
  badgeStyle: string;
  badgeValue: string;
  icon: string;
}
export const SmallCard: FC<Props> = ({
  title,
  subtitle,
  titleValue,
  subtitleValue,
  badgeStyle,
  badgeValue,
  icon,
}) => {
  return (
    <Card className="card-stats">
      <CardBody>
        <div className="card-row">
          <Icon name={icon} color={colors.inkBlue} size={20} />
          <span className="oath-body inkBlue">{title}</span>
        </div>
        <span className="oath-h3 inkBlue">{titleValue}</span>
        <hr />
        <span className="oath-body gray">{subtitle}</span>
        <div className="card-row space-between">
          <Badge className={`big-badge ${badgeStyle}`} pill>
            {badgeValue}
          </Badge>
          <span className="oath-h4 inkBlue">{subtitleValue}</span>
        </div>
      </CardBody>
    </Card>
  );
};
