export const setClientID = (id: string) => {
  localStorage.setItem('clientID', id);
};

export const setClientIDs = (ids: string) => {
  localStorage.setItem('clientIDs', ids);
};

export const setClientNames = (names: string) => {
  localStorage.setItem('clientNames', names);
};

export const setClientName = (name: string) => {
  localStorage.setItem('clientName', name);
};

export const setUserName = (name: string) => {
  localStorage.setItem('userName', name);
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem('accessToken', accessToken);
};

export const setRoleType = (roleType: string) => {
  localStorage.setItem('roleType', roleType);
};

export const setSessionStartDate = (date: string) => {
  sessionStorage.setItem('startDate', date);
};

export const setSessionEndDate = (date: string) => {
  sessionStorage.setItem('endDate', date);
};

export const getClientID = () => localStorage.getItem('clientID');

export const getClientIDs = () => localStorage.getItem('clientIDs');

export const getClientNames = () => localStorage.getItem('clientNames');

export const getClientName = () => localStorage.getItem('clientName');

export const getUserName = () => localStorage.getItem('userName');

export const getAccessToken = () => localStorage.getItem('accessToken');

export const getRoleType = () => localStorage.getItem('roleType');

export const getSessionStartDate = () => sessionStorage.getItem('startDate');

export const getSessionEndDate = () => sessionStorage.getItem('endDate');

export const clearLocalStorage = () => {
  localStorage.removeItem('clientID');
  localStorage.removeItem('clientIDs');
  localStorage.removeItem('clientName');
  localStorage.removeItem('clientNames');
  localStorage.removeItem('userName');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('roleType');
  sessionStorage.removeItem('startDate');
  sessionStorage.removeItem('endDate');
};
