import { Dispatch, SetStateAction, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import { numberWithDot } from 'helpers/utils';
import { CustomSwitch } from 'components/CustomSwitch';

interface Props<T> {
  title: string;
  subtitle: string;
  columns: string[];
  data: T[];
  dimensionOnChange?: Dispatch<SetStateAction<string>>;
  dimensionOptions?: { value: number; label: string }[];
  withPagination?: boolean;
}

export default function RegularTable<
  T extends {
    total?: number;
    donors?: number;
    time?: string;
    name?: string;
    donation_amount?: number;
    ref_code?: string;
  },
>({
  title,
  subtitle,
  columns,
  data,
  dimensionOnChange,
  dimensionOptions,
  withPagination,
}: Props<T>) {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const sortValues = (item: T) => {
    const type = columns[0] === 'Recipient Name' ? 'recipient' : 'page';
    return [item[type], `$${numberWithDot(item.total?.toFixed(2) || 0)}`, item.donors];
  };

  return (
    <Card>
      <CardHeader>
        <div className="card-row space-between">
          <div className="grid-column">
            <span className="oath-h4 inkBlue capitalize">{title}</span>
            <span className="oath-bodysmall gray">{subtitle}</span>
          </div>
          <CustomSwitch options={dimensionOptions} onSelect={dimensionOnChange} />
        </div>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              {columns.map((col: string, i: number) => (
                <th className="oath-eyebrow inkBlue" key={i}>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.slice(currentPage * 5, currentPage * 5 + 5)?.map((item: T, i: number) => (
              <tr key={i}>
                {sortValues(item)?.map((val, j) => (
                  <td key={j} className="oath-bodysmall inkBlue">
                    {val || '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {withPagination && (
          <div className="ReactTable oathBlue-pagination">
            <div className="pagination-bottom">
              <div className="-pagination">
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-previous">
                    <button
                      type="button"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 0}
                      className="-btn"
                    >
                      Previous
                    </button>
                  </div>
                </Col>
                <Col lg="6" style={{ margin: '8px 0' }}>
                  <div className="-next">
                    <button
                      type="button"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(data?.length / pageSize)}
                      className="-btn"
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}
