import Login from 'views/Login';
import Pages from 'views/Pages';
import Dashboard from 'views/Dashboard';
import DonorList from 'views/DonorList';
import PagesDetail from 'views/PagesDetail';
import NotFoundScreen from 'views/NotFoundScreen';

const routes = [
  {
    path: '/',
    name: 'Login',
    element: <Login />,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'Dashboard',
    element: <Dashboard />,
    permission: 'DASHBOARD_ROUTE',
  },
  {
    path: '/donation-list',
    name: 'Donation List',
    icon: 'List',
    element: <DonorList />,
    permission: 'DASHBOARD_DONOR_LIST_ROUTE',
  },
  {
    name: 'Pages',
    icon: 'Copy',
    collapse: true,
    permission: 'PAGES_ROUTE',
    state: 'pagesCollapse',
    children: [
      {
        path: '/pages',
        name: 'All pages',
        element: <Pages />,
        permission: 'PAGES_ROUTE',
      },
      // {
      //   path: '/donation-pages',
      //   name: 'Donation Pages',
      //   element: <Pages />,
      //   permission: 'PAGES_ROUTE',
      // },
    ],
  },
  {
    name: 'PagesDetail',
    path: '/pages_detail',
    icon: 'Copy',
    permission: 'PAGES_DETAIL_ROUTE',
    redirect: true,
    element: <PagesDetail />,
  },
];

export default routes;
