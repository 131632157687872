import { ReactElement, useEffect, useRef, useState } from 'react';
import routes from 'routes';
import { useNavigate } from 'react-router-dom';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Row, Col, Button } from 'reactstrap';
import { has_permission } from 'config/permissions';
import { pageColumns } from 'constants/tables';
import Loader from 'components/Loader';
import Sidebar from 'components/Sidebar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { CustomTable, tableButton } from 'components/CustomTable';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUserState } from 'selectors/user';
import { getPagesState } from 'selectors/pages';
import { getClientID, getClientName, getAccessToken } from 'services/storage';
import { getAllPages, getPages, updatePage } from 'store/pages/actions';
import { ENV_CONFIG } from 'config/environment';
import Icon, { IconNames } from 'components/Icon';
import RecentActivity from 'components/RecentActivity';
import { getDonorData } from 'selectors/donors';
import { PagesFilters } from 'components/Filters/PagesFilters';
import { numberWithDot } from 'helpers/utils';

interface IData {
  id: number;
  pageName: string;
  webUrl: string;
  amounts: string;
  actions: ReactElement;
}
const Pages = () => {
  const mainPanel = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [alert, setAlert] = useState<any>(null);
  const [dataState, setDataState] = useState(Array<IData>());
  const [filterSelected, setFilterSelected] = useState('all');
  const [recentActivityMini, setRecentActivityMini] = useState(false);

  const { pagesState, userState, donors } = useAppSelector(state => ({
    pagesState: getPagesState(state),
    userState: getUserState(state),
    donors: getDonorData(state),
  }));

  useEffect(() => {
    if (getClientID() && getAccessToken()) {
      dispatch(getPages(getClientID() || ''));
      if (!pagesState.allPages) dispatch(getAllPages());
    }
  }, [userState]);

  useEffect(() => {
    if (!pagesState.isLoading && pagesState.data?.pages) {
      if (pagesState.data?.pageCreated) dispatch(getPages(getClientID() || ''));
      setDataState(
        pagesState.data.pages
          .filter(page =>
            filterSelected === 'all' ? page.tag.is_enabled : page.tag.status === filterSelected
          )
          ?.map((prop, key) => {
            return {
              id: key,
              pageName: prop.tag.value,
              webUrl: `${ENV_CONFIG().PARTNER_PAGE}${prop.tag.query_str}`,
              amounts: `[${prop.tag.donation_amounts.toString()}]`,
              actions: (
                <div className="grid-column">
                  <div className="table-action-row">
                    {has_permission('PAGES_ROUTE', 'U') &&
                      tableButton(handleUpdate, 'edit', prop.tag.id)}
                    {tableButton(handleUpdate, 'preview', prop.tag.query_str)}
                  </div>
                  <div className="table-action-row" style={{ margin: 0 }}>
                    {has_permission('PAGES_ROUTE', 'U') &&
                      tableButton(handleUpdate, 'duplicate', prop.tag.id)}
                    {has_permission('PAGES_ROUTE', 'D') &&
                      tableButton(handleUpdate, 'delete', prop.tag.id)}
                  </div>
                </div>
              ),
            };
          })
      );
    }
  }, [pagesState, filterSelected]);

  const handleCreate = () => {
    navigate('/pages_detail');
  };

  const handleDelete = param => {
    const successDelete = () => {
      const body = pagesState?.data?.pages?.find(page => page.tag.id === param);
      if (body) {
        const updateBody = {
          client_id: getClientID() || '',
          page: {
            ...body,
            tag: {
              ...body.tag,
              is_enabled: false,
            },
          },
        };
        dispatch(updatePage(updateBody));
      }
      setAlert(null);
    };
    setAlert(
      <ReactBSAlert
        title={
          <div className="grid-column">
            <span className="oath-eyebrow inkBlue">Delete Page</span>
            <span className="oath-body inkBlue">
              Are you sure you would like to delete this page? This can’t be undone.
            </span>
            <Button block className="btn-inkBlue" onClick={successDelete}>
              Delete
            </Button>
            <span className="oath-bodysmall link-text inkBlue" onClick={() => setAlert(null)}>
              Cancel
            </span>
          </div>
        }
        onConfirm={() => successDelete()}
        onCancel={() => setAlert(null)}
        showConfirm={false}
      />
    );
  };

  const handleUpdate = (type: string, param: string | number) => {
    if (type === 'preview') window.open(`${ENV_CONFIG().PARTNER_PAGE}${param}`, '_blank');
    if (type === 'edit') navigate(`/pages_detail?tag_id=${param}`);
    if (type === 'duplicate') navigate(`/pages_detail?tag_id=${param}&copy`);
    if (type === 'delete') handleDelete(param);
  };

  return (
    <>
      {alert}
      {pagesState.isLoading && <Loader type="dots" />}
      <div className="wrapper">
        <Sidebar routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <Row className="filters-row">
            <PagesFilters setFilterSelected={setFilterSelected} />
            <div
              className={`recent-activity-clickable ${recentActivityMini ? 'recent-activity-mini' : ''}`}
              onClick={() => setRecentActivityMini(prev => !prev)}
            >
              <Icon
                name={IconNames.ChevronLeft}
                className="chevron-left"
                color="#04053A"
                style={{ display: 'none' }}
              />
              <span className="oath-h4 inkBlue">Recent Activity</span>
              <Icon name={IconNames.ChevronRight} className="chevron-right" color="#04053A" />
            </div>
          </Row>
          {!has_permission('SCREEN_VIEW_PERMISSION', 'R') ? (
            <div className="content">
              <h1 className="title-permission">
                Sorry, you don't have permission to see this screen. Switch the environment to
                staging.
              </h1>
            </div>
          ) : (
            <div className={`content ${recentActivityMini ? 'content-mini' : ''}`}>
              <RecentActivity isMini={recentActivityMini} donors={donors} />
              <Row className="custom-header-container">
                <Col lg="9" md="8" sm="12" className="grid-column">
                  <span className="oath-h3 inkBlue">{getClientName()} Pages</span>
                  <span className="oath-body inkBlue">
                    {numberWithDot(dataState?.length)} Total
                  </span>
                </Col>
                {has_permission('PAGES_ROUTE', 'C') && (
                  <Col lg="3" md="4" sm="12" style={{ alignSelf: 'flex-end' }}>
                    <Button block className="btn-inkBlue" onClick={handleCreate}>
                      Create New Page
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="12">
                  <CustomTable
                    subtitle="A full list of your pages can be found below. Reach out to partners@oath.vote if you need support."
                    data={dataState}
                    columns={pageColumns}
                    withPagination
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Pages;
