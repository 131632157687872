import { filterDonationOptions } from 'constants/filters';

export const DonationFilters = () => {
  return (
    <div className="date-filter-container">
      {filterDonationOptions.map((option, index) => (
        <button key={index} className="date-filter-item active">
          <span className="oath-body inkBlue">{option}</span>
        </button>
      ))}
    </div>
  );
};
